// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_API_URL;

class TenderService {
    getAllTenders() {
        return axios.get(API_URL + 'tenders', { headers: authHeader() })
    }
    getTendersCount() {
        return axios.get(API_URL + 'get-tenders-count', { headers: authHeader() })
    }
    getClosedTendersCount() {
        return axios.get(API_URL + 'get-closed-tenders-count', { headers: authHeader() })
    }
    getTender3() {
        return axios.get(API_URL + 'tenders/3', { headers: authHeader() })
    }
    getAllLikedTenders(id) {
        return axios.get(API_URL + 'tenders-selected/' + id, { headers: authHeader() })
    }
    getEvaluationTenders(user) {
        return axios.get(API_URL + 'tenders-eval/' + user, { headers: authHeader() })
    }
    getTenderApplicants(item, evaluator_id) {
        return axios.get(API_URL + 'tender-applicants/' + item.id + '/' + evaluator_id, { headers: authHeader() })
    }
    showTender(id) {
        return axios.get(API_URL + 'tenders/' + id, { headers: authHeader() })
    }
    getApplicantUploadedRequiredDocs(tender, user) {
        return axios.get(API_URL + 'tenders-uploaded-required-doc/' + tender + '/' + user, { headers: authHeader() })
    }
    getPreqUploadedRequiredDocs(user) {
        return axios.get(API_URL + 'preq-uploaded-required-doc/' + user, { headers: authHeader() })
    }
    getApplicantUploadedFinancialDocs(tender, user) {
        return axios.get(API_URL + 'tenders-uploaded-financial-doc/' + tender + '/' + user, { headers: authHeader() })
    }
    getApplicantUploadedTechnicalDocs(tender, user) {
        return axios.get(API_URL + 'tenders-uploaded-technical-doc/' + tender + '/' + user, { headers: authHeader() })
    }
    storeTender(resource) {
        return axios.post(API_URL + 'tenders', resource, { headers: authHeader() })
    }
    updateTender(resource) {
        return axios.put(API_URL + 'tenders/' + resource.id, resource, { headers: authHeader() })
    }
    awardTender(resource) {
        return axios.post(API_URL + 'tenders-award/' + resource.id, resource, { headers: authHeader() })
    }
    deleteTender(resource) {
        return axios.delete(API_URL + 'tenders/' + resource.id, { headers: authHeader() })
    }
    getCategories() {
        return axios.get(API_URL + 'tenders-category', { headers: authHeader() })
    }
    getAllRequiredDocuments() {
        return axios.get(API_URL + 'required-documents', { headers: authHeader() })
    }
    getAllTechnicalDocuments() {
        return axios.get(API_URL + 'technical-documents', { headers: authHeader() })
    }
    getAllFinancialDocuments() {
        return axios.get(API_URL + 'financial-documents', { headers: authHeader() })
    }
    getMarketplaceTenders(id) {
        return axios.get(API_URL + 'marketplace-tenders/' + id, { headers: authHeader() })
    }
    getAppliedTenders(id) {
        return axios.get(API_URL + 'applied-tenders/' + id, { headers: authHeader() })
    }
    getAllTendersByCategory() {
        return axios.get(API_URL + 'tenders-by-category', { headers: authHeader() })
    }
    addDocument(resource) {
        return axios.post(API_URL + 'tenders-add-doc', resource, { headers: authHeader() })
    }
    getPublished() {
            return axios.get(API_URL + 'tenders-published', { headers: authHeader() })
        }
        /**
         * Tender docs
         */

    deleteTenderDocument(resource) {
        return axios.delete(API_URL + 'tender-documents/' + resource.id, { headers: authHeader() })
    }
    storeTenderDocument(resource) {
        return axios.post(API_URL + 'tender-documents', resource, { headers: authHeader() })
    }
    updateTenderDocument(resource) {
        return axios.put(API_URL + 'tender-documents/' + resource.id, resource, { headers: authHeader() })
    }
    storeClarification(resource) {
        return axios.post(API_URL + 'tender-clarification', resource, { headers: authHeader() })
    }
    updateClarification(resource) {
        return axios.put(API_URL + 'tender-clarification/' + resource.tender_id, resource, { headers: authHeader() })
    }
    showInterest(resource) {
        return axios.post(API_URL + 'tenders-show-interest', resource, { headers: authHeader() })
    }
    storeDocument(resource) {
        return axios.post(API_URL + 'tenders-show-interest', resource, { headers: authHeader() })
    }
    submitTenderApplication(resource) {
        return axios.post(API_URL + 'tenders-submit-application', resource, { headers: authHeader() })
    }
    submitPrequalificationApplication(resource) {
        return axios.post(API_URL + 'preq-submit-application', resource, { headers: authHeader() })
    }
    addApplicantRequiredDocument(resource) {
        return axios.post(API_URL + 'tenders-applicant-required-doc', resource, { headers: authHeader() })
    }
    addPreqRequiredDocument(resource) {
        return axios.post(API_URL + 'preq-applicant-required-doc', resource, { headers: authHeader() })
    }
    addApplicantTechnicalDocument(resource) {
        return axios.post(API_URL + 'tenders-applicant-technical-doc', resource, { headers: authHeader() })
    }
    addApplicantFinancialDocument(resource) {
        return axios.post(API_URL + 'tenders-applicant-financial-doc', resource, { headers: authHeader() })
    }

    /**
     * Tender evaluation
     */

    addRequiredScore(resource) {
        return axios.post(API_URL + 'eval-score-required', resource, { headers: authHeader() })
    }
    addTechnicalScore(resource) {
        return axios.post(API_URL + 'eval-score-technical', resource, { headers: authHeader() })
    }
    addFinancialScore(resource) {
        return axios.post(API_URL + 'eval-score-financial', resource, { headers: authHeader() })
    }
    submitTenderEvaluation(resource) {
        return axios.post(API_URL + 'tenders-submit-evaluation', resource, { headers: authHeader() })
    }
    getRequiredScore(tender, applicant, evaluator) {
        return axios.get(API_URL + 'tenders-required-score/' + tender + '/' + applicant + '/' + evaluator, { headers: authHeader() })
    }
    getTechnicalScore(tender, applicant, evaluator) {
        return axios.get(API_URL + 'tenders-technical-score/' + tender + '/' + applicant + '/' + evaluator, { headers: authHeader() })
    }
    getFinancialScore(tender, applicant, evaluator) {
        return axios.get(API_URL + 'tenders-financial-score/' + tender + '/' + applicant + '/' + evaluator, { headers: authHeader() })
    }
    getEvaluationScores(tender) {
        return axios.get(API_URL + 'tender-evaluation-scores/' + tender, { headers: authHeader() })
    }
    getReportSummary(tender) {
        return axios.get(API_URL + 'get-applicant-totals/' + tender, { headers: authHeader() })
    }
    getEvaluationReport(tender) {
        return axios.get(API_URL + 'evaluation-report/' + tender, { headers: authHeader() })
    }
    getAllEvaluators() {
        return axios.get(API_URL + 'tender-evaluators-all', { headers: authHeader() })
    }
    getTenderEvaluators(tender) {
        return axios.get(API_URL + 'tender-evaluators/' + tender, { headers: authHeader() })
    }
    addEvaluators(resource) {
        return axios.post(API_URL + 'tenders-add-evaluators', resource, { headers: authHeader() })
    }
    completeEvaluation(resource) {
        return axios.post(API_URL + 'complete-evaluation', resource, { headers: authHeader() })
    }
    getAllQuotations() {
        return axios.get(API_URL + 'get-quotations-count', { headers: authHeader() })
    }

}

export default new TenderService()