<template>
  <v-container
    fill-height
    fluid
    class="lighten-5 text-center"
    style="position: relative"
  >
    <!-- Loader  -->
    <v-row align="center" justify="center" v-if="dialog">
      <v-col>
        <v-progress-circular
          indeterminate
          color="error"
          class="my-3"
        ></v-progress-circular>
        <p caption>Loading... Please wait</p>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="pageReady">
      <v-col cols="12" sm="12" class="mx-auto">
        <!-- Default Login  -->
        <v-card
          v-if="this.config.login_branded == 1"
          class="mx-auto mt-16 pt-5"
          max-width="412"
          outlined
        >
          <v-row no-gutters>
            <!-- Right band  -->
            <v-col cols="12" order="last" border="none" class="my-n">
              <div>
                <div class="d-flex justify-center">
                  <v-img
                    lazy-src="img/logo-128.png"
                    max-height="128"
                    max-width="128"
                    src="img/logo-128.png"
                  ></v-img>
                </div>
                <div class="mt-3">
                  <h4 class="grey--text text--darken-2 d-flex justify-center">
                    SIGN IN
                  </h4>
                </div>

                <v-divider class="mt-1 mb-1"></v-divider>
                <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class="mt-5"
                  >
                    <v-text-field
                      dense
                      v-model="user.email"
                      color="grey"
                      append-icon="mdi-account-outline"
                      :rules="nameRules"
                      label="Email"
                      outlined
                      required
                      placeholder=" "
                    ></v-text-field>

                    <v-text-field
                      dense
                      v-model="user.password"
                      color="grey"
                      append-icon="mdi-lock-outline"
                      :rules="passwordRules"
                      :type="'password'"
                      name="input-10-1"
                      label="Password"
                      hint="At least 8 characters"
                      outlined
                      placeholder=" "
                    ></v-text-field>
                    <div class="d-flex mt-n1">
                      <v-checkbox
                        v-model="remember"
                        :label="'Remember me'"
                        color="grey"
                        class="ml-1 mt-1"
                      ></v-checkbox>
                      <a
                        link
                        class="ml-auto mt-2 text"
                        @click="gotToForgotPassword"
                        >Forgot Password?</a
                      >
                    </div>
                  </v-form>
                </v-card-text>
                <v-divider class="mt-n4"></v-divider>
                <v-card-actions class="mt-3">
                  <v-btn
                    color="white"
                    medium
                    class="ml-3 grey--text"
                    @click="gotToRegister"
                    outlined
                    >Register</v-btn
                  >
                  <v-btn
                    :loading="loading"
                    color="grey"
                    elevation="2"
                    medium
                    width="80"
                    class="ml-auto white--text"
                    @click="handleLogin"
                    >Login</v-btn
                  >
                </v-card-actions>
              </div>
            </v-col>
          </v-row>

          <div
            class="grey white--text d-flex justify-center pa-2 mt-2"
            border="none"
          >
            Procurement Portal
          </div>
        </v-card>
        <!-- Branded Login  -->
        <v-card v-else class="mx-auto mt-16 pt-5" max-width="814" outlined>
          <v-row no-gutters>
            <!-- Left band  -->
            <v-col
              cols="12"
              md="6"
              order="first"
              class="grey mb-n2 mt-n5 lighten-4"
            >
              <div class="mt-5 px-5">
                <div class="d-flex justify-center">
                  <v-img
                    :lazy-src="this.computedLogo"
                    max-height="178"
                    max-width="178"
                    :src="this.computedLogo"
                  ></v-img>
                </div>
                <h4
                  class="grey--text text--darken-2 mt-5 d-flex justify-center"
                >
                  WELCOME TO
                </h4>
                <h2
                  class="mb-3 error--text text--darken-1 d-flex justify-center"
                >
                  E-PROCUREMENT PORTAL
                </h2>
                <p class="grey--text text--darken-2">
                  Our e-procurement solutions are designed for organizations to
                  generate more value, influence innovation, and reduce risk.
                </p>
                <p class="grey--text text--darken-2">
                  Our paperless environment streamliness your procurement
                  department's process and integrates effortlessly to reduce
                  your IT costs and drive impactful relations.
                </p>
              </div>
            </v-col>
            <!-- Right band  -->
            <v-col cols="12" md="6" order="last" border="none" class="my-n">
              <div>
                <div class="d-flex justify-center mt-3">
                  <v-img
                    lazy-src="img/logo-128.png"
                    max-height="178"
                    max-width="178"
                    src="img/logo-128.png"
                  ></v-img>
                </div>
                <div class="mt-3">
                  <h4 class="grey--text text--darken-2 d-flex justify-center">
                    SIGN IN
                  </h4>
                  <span
                    class="d-flex justify-center mx-5 grey--text text--darken-2"
                  >
                    <caption>
                      Is your company already registered? use your company
                      account to Sign In.
                    </caption>
                  </span>
                </div>

                <v-divider class="mt-1 mb-1"></v-divider>
                <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class="mt-5"
                  >
                  <v-text-field
                      dense
                      v-model="user.email"
                      color="grey"
                      append-icon="mdi-account-outline"
                      :rules="nameRules"
                      label="Email"
                      outlined
                      required
                      placeholder=" "
                    ></v-text-field>

                    <v-text-field
                      dense
                      v-model="user.password"
                      color="grey"
                      append-icon="mdi-lock-outline"
                      :rules="passwordRules"
                      :type="'password'"
                      name="input-10-1"
                      label="Password"
                      hint="At least 8 characters"
                      outlined
                      placeholder=" "
                    ></v-text-field>
                    <div class="d-flex mt-n1">
                      <v-checkbox
                        v-model="remember"
                        :label="'Remember me'"
                        color="grey"
                        class="ml-1 mt-1"
                      ></v-checkbox>
                    </div>
                  </v-form>
                </v-card-text>
                <v-divider class="mt-n4"></v-divider>
                <a link class="ml-auto mt-2 text" @click="gotToForgotPassword"
                  >Forgot Password?</a
                >
                <v-card-actions class="mt-3">

                  <v-btn
                    color="white"
                    medium
                    class="ml-3 grey--text"
                    @click="gotToRegister"
                    outlined
                    >Register</v-btn
                  >
                  <v-btn
                    :loading="loading"
                    color="grey"
                    elevation="2"
                    medium
                    width="80"
                    class="ml-auto white--text"
                    @click="handleLogin"
                    >Login</v-btn
                  >
                </v-card-actions>
              </div>
            </v-col>
          </v-row>

          <div
            class="grey white--text d-flex justify-center pa-2 mt-2"
            border="none"
          >
            Procurement Portal
          </div>
        </v-card>

        <div class="d-flex justify-center mt-5">
          <span class="text-caption grey--text"
            >{{ this.currentYear }} &copy; Software Technologies Ltd.</span
          >
        </div>
        <div class="d-flex justify-center">
          <v-img
            lazy-src="img/vendor.png"
            max-height="100"
            max-width="100"
            src="img/vendor.png"
          ></v-img>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import User from "../../models/user";
import ConfigService from "../../services/config.service";

export default {
  data: () => ({
    dialog: true,
    pageReady: false,
    user: new User("", ""),
    valid: true,
    remember: 0,
    timeout: 2000,
    passwordRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    nameRules: [
      (v) => !!v || "E-mail is required",
      // (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    currentYear: new Date().getFullYear(),
    config: {},
  }),
  name: "Login",
  opts: {
    theme: {
      dark: false,
    },
  },
  components: {},

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    loading() {
      return this.$store.state.loading;
    },
    computedLogo() {
      return this.config.logo_url;
    },
  },
  created() {
    localStorage.clear();
    this.systemConfig();
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    gotToRegister() {
      this.$router.push("/register");
    },
    gotToForgotPassword() {
      this.$router.push("/forgot-password");
    },
    systemConfig() {
      return ConfigService.index().then(
        (response) => {
          setTimeout(() => {
            if (response.status == 200) {
              this.config = response.data.data;
              this.dialog = false;
              this.pageReady = true;
            } else {
              console.log(response.data.error);
            }
          }, 1000);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    handleLogin() {
      this.$refs.form.validate();
      if (this.user.email && this.user.password) {
        this.$store.dispatch("setLoading", true);
        this.$store.dispatch("auth/login", this.user).then(
          (response) => {
            if (response.status == 200) {
              if (response.data.user.is_admin == 1) {
                setTimeout(() => {
                  this.$store.dispatch("setLoading", false);
                  this.$router.push("/dashboard");
                }, 1500);
              } else {
                setTimeout(() => {
                  this.$store.dispatch("setLoading", false);
                  if (response.data.user.company.profile_complete) {
                    this.$router.push("/marketplace");
                  } else {
                    this.$router.push("/complete-profile");
                  }
                }, 1500);
              }
            }
          },
          (error) => {
            this.$store.dispatch("setLoading", false);
            console.log(error);
          }
        );
      }
    },
  },
};
</script>
