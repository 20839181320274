import  TenderService from '../services/tender.service'
import AuthService from '../services/auth.service'

const initialState ={
    selected: {},
    allTenders: {},
    applicant_uploaded_technical_docs: {},
    applicant_uploaded_required_docs: {},
    applicant_uploaded_financial_docs: {},
    };

export const tender = {
    namespaced: true,
    state: initialState,
    actions: {
        list({ commit }) {
            return TenderService.listAll().then(
                response => {
                    console.log()
                    commit('loginSuccess', response.data)
                    return Promise.resolve(response.data)
                },
                error => {
                    commit('loginFailure', error)
                    return Promise.reject(error)
                }
            )
        },
        setSelected({ commit }, item){
            commit('setSelected', item)
        },
        setApplicantUploadedRequiredDocs({ commit }, item){
            commit('setApplicantUploadedRequiredDocs', item)
        },
        setApplicantUploadedTechnicalDocs({ commit }, item){
            commit('setApplicantUploadedTechnicalDocs', item)
        },
        setApplicantUploadedFinancialDocs({ commit }, item){
            commit('setApplicantUploadedFinancialDocs', item)
        },
        logout({ commit }) {
            AuthService.logout()
            commit('logout')
        },
        deleteDocument({ commit }, item) {
            return TenderService.deleteTenderDocument(item).then(
                response => {
                    commit('delDocumentSuccess', response)
                    console.log(response.data);
                    return Promise.resolve(response.data)
                },
                error => {
                    console.log(error)
                    commit('delDocumentFailure', error)
                    return Promise.reject(error)
                }
            )
        },
    },
    mutations: {
        // handler: () => { },
        loginSuccess(state, response) {
            state.status.loggedIn = true
            state.snackbar.show = true
            state.snackbar.color = 'success'
            state.snackbar.message = 'User login successful!'
            localStorage.setItem('user', JSON.stringify(response.data.user))
            localStorage.setItem('token', response.data.access_token)
            state.user = response.data.user
        },
        loginFailure(state,error) {
            console.log(error)
            state.status.loggedIn = false
            state.user = null
            state.snackbar.show = true
            state.snackbar.color = 'error'
            state.snackbar.message = error.response.data.message ||
              (error.response && error.response.data) ||
              error.mesage || 
              error.toString();
        },
        logout(state) {
            state.status.loggedIn = false
            state.user = null
            state.snackbar.show = false
            state.snackbar.color = ''
            state.snackbar.message = ''
        },
        registerSuccess(state, response) {
            state.status.loggedIn = true
            state.snackbar.show = true
            state.snackbar.color = 'success'
            state.snackbar.message = 'User registration successful!'
            state.user = response.data.user
        },
        registerFailure(state, error) {
            console.log(error.response)
            state.status.loggedIn = false
            state.snackbar.show = true
            state.snackbar.color = 'error'
            state.snackbar.message = error.response.data.message
            state.snackbar.message = error.response.data.errors
        },
        delDocumentSuccess(state, response) {
            console.log(response.data);
            console.log(state.selected);
        },
        delDocumentFailure(state, error) {
            console.log(error);
            console.log(state.selected);
        },
        setSelected(state, item) {
            state.selected = item
        },
        setApplicantUploadedRequiredDocs(state, item) {
            state.applicant_uploaded_required_docs = item
        },
        setApplicantUploadedTechnicalDocs(state, item) {
            state.applicant_uploaded_technical_docs = item
        },
        setApplicantUploadedFinancialDocs(state, item) {
            state.applicant_uploaded_financial_docs = item
        },
        
    },
    getters: {
        user: (state) => state.user
    }

}
