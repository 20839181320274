// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_API_URL;

class UserService {

    /**
     * Users
     */
    getAllUsers() {
        return axios.get(API_URL + 'users', { headers: authHeader() })
    }
    getAllCompanyUsers() {
        let user = JSON.parse(localStorage.getItem("user"));
        console.log(user);
        return axios.get(API_URL + 'users/company/' + user.company_id, { headers: authHeader() })
    }
    storeUser(user) {
        return axios.post(API_URL + 'users', user, { headers: authHeader() })
    }
    updateUser(user) {
        return axios.put(API_URL + 'users/' + user.id, user, { headers: authHeader() })
    }
    getUser(user) {
        return axios.get(API_URL + 'users/' + user.id, { headers: authHeader() })
    }
    deleteUser(user) {
        return axios.delete(API_URL + 'users/' + user.id, { headers: authHeader() })
    }
    getAllMonthlyUsersByRole() {
        return axios.get(API_URL + 'get-users-by-role', { headers: authHeader() })
    }
    /**
    * Roles
    */
    getAllRoles() {
        return axios.get(API_URL + 'roles', { headers: authHeader() })
    }
    storeRole(role) {
        return axios.post(API_URL + 'roles', role, { headers: authHeader() })
    }
    updateRole(role) {
        return axios.put(API_URL + 'roles/' + role.id, role, { headers: authHeader() })
    }
    deleteRole(role) {
        return axios.delete(API_URL + 'roles/' + role.id, { headers: authHeader() })
    }
    forgotPassword(user) {
        return axios.post(API_URL + 'auth/forgot-password', user, { headers: authHeader() })
    }
    resetPassword(user) {
        return axios.post(API_URL + 'auth/reset-password', user, { headers: authHeader() })
    }
    checkResetToken(token) {
        return axios.get(API_URL + 'auth/validate-token/'+token)
    }
}

export default new UserService()
