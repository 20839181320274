<template>
  <v-container
    fill-height
    fluid
    class="lighten-5 text-center"
    style="position: relative"
  >
    <!-- Loader  -->
    <v-row align="center" justify="center" v-if="dialog">
      <v-col>
        <v-progress-circular
          indeterminate
          color="error"
          class="my-aut"
        ></v-progress-circular>
        <p caption>Loading... Please wait</p>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="pageReady">
      <v-col cols="12" sm="12" class="mx-auto">
        <!-- Default Login  -->
        <v-card
          class="mx-auto mt-16 pt-5"
          max-width="412"
          outlined
        >
          <v-row no-gutters>
            <!-- Right band  -->
            <v-col cols="12" order="last" border="none" class="my-n">
              <div>
                <div class="d-flex justify-center">
                  <v-img
                    lazy-src="img/logo-128.png"
                    max-height="178"
                    max-width="178"
                    src="img/logo-128.png"
                  ></v-img>
                </div>
                <div class="mt-3">
                  <h4 class="grey--text text--darken-2 d-flex justify-center">
                    Reset Password
                  </h4>
                  <span
                    class="d-flex justify-center mx-5 grey--text text--darken-2"
                  >
                  </span>
                </div>

                <v-divider class="mt-1 mb-1"></v-divider>
                <v-card-text>
                  <v-form ref="form" v-model="valid" class="mt-5">
                    <v-text-field
                      dense
                      v-model="user.password"
                      color="grey"
                      append-icon="mdi-lock-outline"
                      :rules="passwordRules"
                      :type="'password'"
                      name="input-10-1"
                      label="New Password"
                      hint="At least 8 characters"
                      outlined
                      placeholder=" "
                    ></v-text-field>

                    <v-text-field
                      dense
                      v-model="user.password_confirmation"
                      color="grey"
                      append-icon="mdi-lock-outline"
                      :rules="passwordRules"
                      :type="'password'"
                      name="input-10-1"
                      label="Confirm Password"
                      hint="At least 8 characters"
                      outlined
                      placeholder=" "
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-divider class="mt-n4"></v-divider>
                <v-card-actions class="mt-3">
                  <a class="ml-4 grey--text" href="/login">Login</a>
                  <v-btn
                    color="grey"
                    @click="handleReset"
                    dark
                    class="ml-auto white--text"
                    :loading="loading"
                    >Reset</v-btn
                  >
                </v-card-actions>
              </div>
            </v-col>
          </v-row>

          <div
            class="grey white--text d-flex justify-center pa-2 mt-2"
            border="none"
          >
            Procurement Portal
          </div>
        </v-card>

        <div class="d-flex justify-center mt-5">
          <span class="text-caption grey--text"
            >{{ this.currentYear }} &copy; Software Technologies Ltd.</span
          >
        </div>
        <div class="d-flex justify-center">
          <v-img
            lazy-src="img/vendor.png"
            max-height="100"
            max-width="100"
            src="img/vendor.png"
          ></v-img>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConfigService from "../../services/config.service";
import UserService from "../../services/user.service";

export default {
  data: () => ({
    dialog: true,
    pageReady: false,
    user: {},
    valid: true,
    remember: 0,
    timeout: 2000,
    passwordRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    nameRules: [
      (v) => !!v || "E-mail is required",
      // (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    currentYear: new Date().getFullYear(),
    config: {},
  }),
  name: "Login",
  opts: {
    theme: {
      dark: false,
    },
  },
  components: {},

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    loading() {
      return this.$store.state.loading;
    },
    computedLogo() {
      return this.config.logo_url;
    },
    resetToken() {
      return this.$route.query.token;
    },
  },
  created() {
    console.log(this.resetToken);
    this.systemConfig();
    if (!this.resetToken) {
      this.$store.dispatch(
        "alert/error",
        "Session expired, try again resetting your password!"
      );
      this.$router.push("/login");
    } else {
      this.checkTokenValidity();
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    gotToRegister() {
      this.$router.push("/register");
    },
    systemConfig() {
      return ConfigService.index().then(
        (response) => {
          setTimeout(() => {
            if (response.status == 200) {
              this.config = response.data.data;
              this.dialog = false;
              this.pageReady = true;
            } else {
              console.log(response.data.error);
            }
          }, 1000);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    checkTokenValidity() {
      UserService.checkResetToken(this.resetToken).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch(
              "alert/info",
              "Use this form to set your new password!"
            );
          } else {
            this.$store.dispatch("alert/error", response.data.message);
            this.$router.push("/login");
          }
        },
        (error) => {
          console.log(error);
          this.$store.dispatch("alert/error", error.response.data.message);
          this.$router.push("/login");
        }
      );
    },
    handleReset() {
      console.log("resetting...");
      this.$refs.form.validate();
      if (this.user.password && this.user.password_confirmation) {
        this.$store.dispatch("setLoading", true);
        this.user.token = this.resetToken;
        UserService.resetPassword(this.user).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("setLoading", false);
              this.$store.dispatch(
                "alert/success",
                "Password was changed successfuly!"
              );
              this.$router.push("/login");
            } else {
              this.$store.dispatch("alert/error", response.data.message);
              this.$store.dispatch("setLoading", false);
            }
          },
          (error) => {
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
            this.$store.dispatch("setLoading", false);
          }
        );
      }
    },
  },
};
</script>
