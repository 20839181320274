import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Pages/Login.vue'
import Register from '../views/Pages/Register.vue'
import ResetPassword from '../views/Pages/ResetPassword.vue'
import ForgotPassword from '../views/Pages/ForgotPassword.vue'

Vue.use(VueRouter)

const routes = [

    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword
    },

    {
        path: '/',
        component: () =>
            import ('../views/Layout/Main.vue'),
        children: [{
                path: '/pg',
                name: 'PG',
                component: () =>
                    import ('../views/Pages/PG.vue'),
            },
            {
                path: '/',
                name: 'MarketPlace',
                component: () =>
                    import ('../views/Pages/MarketPlace.vue'),
            },
            {
                path: '/dashboard',
                component: () =>
                    import ('../views/Pages/Dashboard.vue'),
            },
            {
                path: '/marketplace',
                component: () =>
                    import ('../views/Pages/MarketPlace.vue'),
            },
            {
                path: '/tenders',
                component: () =>
                    import ('../views/Pages/Tenders.vue'),
            },
            {
                path: '/roles',
                component: () =>
                    import ('../views/Pages/Roles.vue'),
            },
            {
                path: '/tenders-status',
                component: () =>
                    import ('../views/Pages/TenderStatus.vue'),
            },
            {
                path: '/mandatory-tender-documents',
                component: () =>
                    import ('../views/Pages/TenderDocumentsMandatory.vue'),
            },
            {
                path: '/supplier-category-documents-view/:id/:name',
                component: () =>
                    import ('../views/Pages/SupplierCategoryDocumentsView.vue'),
                props: true,
            },
            {
                path: '/technical-tender-documents',
                component: () =>
                    import ('../views/Pages/TenderDocumentsTechnical.vue'),
            },
            {
                path: '/financial-tender-documents',
                component: () =>
                    import ('../views/Pages/TenderDocumentsFinancial.vue'),
            },
            {
                path: '/tender-category',
                component: () =>
                    import ('../views/Pages/TenderCategory.vue'),
            },
            {
                path: '/bids',
                component: () =>
                    import ('../views/Pages/Bids.vue'),
            },
            {
                path: '/supplier-category',
                component: () =>
                    import ('../views/Pages/SupplierCategory.vue'),
            },
            {
                path: '/tender-preview/:id',
                component: () =>
                    import ('../views/Pages/TenderPreview.vue'),
                props: true,
            },
            {
                path: '/pre-application-preview/:id',
                component: () =>
                    import ('../views/Pages/PreqApplicationPreview.vue'),
                props: true,
            },
            {
                path: '/tender-preview2',
                component: () =>
                    import ('../views/Pages/TenderPreview2.vue'),
            },
            {
                path: '/clarification/:id',
                component: () =>
                    import ('../views/Pages/TenderClarifications.vue'),
                props: true,
            },
            {
                path: '/users',
                component: () =>
                    import ('../views/Pages/Users.vue'),
            },
            {
                path: '/settings',
                component: () =>
                    import ('../views/Pages/Settings.vue'),
            },
            {
                path: '/privileges',
                component: () =>
                    import ('../views/Pages/Privileges.vue'),
            },
            {
                path: '/privileges-group',
                component: () =>
                    import ('../views/Pages/PrivilegesGroup.vue'),
            },
            {
                path: '/company-users',
                component: () =>
                    import ('../views/Pages/CompanyUsers.vue'),
            },
            {
                path: '/suppliers',
                component: () =>
                    import ('../views/Pages/Suppliers.vue'),
            },
            {
                path: '/requisition',
                component: () =>
                    import ('../views/Pages/Requisition.vue'),
            },
            {
                path: '/authority-approvals',
                component: () =>
                    import ('../views/Pages/AuthorityApprovals.vue'),
            },
            {
                path: '/need-identification',
                component: () =>
                    import ('../views/Pages/NeedIdentification.vue'),
            },
            {
                path: '/supplier-selection/:token?',
                component: () =>
                    import ('../views/Pages/SupplierSelection.vue'),
            },
            {
                path: '/contract-initiation/:token?',
                component: () =>
                    import ('../views/Pages/Initiation.vue'),
            },
            {
                path: '/contracts',
                component: () =>
                    import ('../views/Pages/Contracts.vue'),
            },
            {
                path: '/contract-templates',
                component: () =>
                    import ('../views/Pages/ContractTemplates.vue'),
            },
            {
                path: '/countries',
                component: () =>
                    import ('../views/Pages/Countries.vue'),
            },
            {
                path: '/currency',
                component: () =>
                    import ('../views/Pages/Currency.vue'),
            },
            {
                path: '/purchase-requisition',
                component: () =>
                    import ('../views/Pages/PurchaseRequisition.vue'),
            },

            // generate LPO
            {
                path: '/generate-lpo',
                component: () =>
                    import ('../views/Pages/LPO.vue'),
            },
            {
                path: '/supply-requests',
                component: () =>
                    import ('../views/Pages/SupplyRequest.vue'),
            },
            {
                path: '/requisition-approval',
                component: () =>
                    import ('../views/Pages/RequisitionApproval.vue'),
            },
            {
                path: '/requisition-view/:id',
                component: () =>
                    import ('../views/Pages/RequisitionApproval.vue'),
                props: true,
            },
            {
                path: '/approvals',
                component: () =>
                    import ('../views/Pages/Approvals.vue'),
            },
            {
                path: '/workflows',
                component: () =>
                    import ('../views/Pages/WorkFlows.vue'),
            },
            {
                path: '/approvers',
                component: () =>
                    import ('../views/Pages/Approvers.vue'),
            },
            {
                path: '/approval-groups',
                component: () =>
                    import ('../views/Pages/ApprovalGroups.vue'),
            },

            {
                path: '/suppliers-view/:id/:name',
                component: () =>
                    import ('../views/Pages/SuppliersView.vue'),
                props: true
            },
            {
                path: '/quotations',
                component: () =>
                    import ('../views/Pages/Quotations.vue'),
            },
            {
                path: '/supplier-quotations',
                component: () =>
                    import ('../views/Pages/SupplierQuotations.vue'),
            },
            {
                path: '/evaluation',
                component: () =>
                    import ('../views/Pages/Evaluation.vue'),
            },
            {
                path: '/register-supplier',
                component: () =>
                    import ('../views/Pages/SupplierRegistration.vue'),
            },
            {
                path: '/register-supplier-preview',
                component: () =>
                    import ('../views/Pages/SupplierRegistrationPreview.vue'),
            },
            {
                path: '/supplier-prequalifications',
                component: () =>
                    import ('../views/Pages/SupplierPrequalification.vue'),
            },
            {
                path: '/business-types',
                component: () =>
                    import ('../views/Pages/BusinessTypes.vue'),
            },
            // notification routes
            {
                path: '/user-notifications',
                component: () =>
                    import ('../views/Pages/Notifications.vue'),
            },
            {
                path: '/notification-groups',
                component: () =>
                    import ('../views/Pages/NotificationGroups.vue'),
            },
            {
                path: '/notification-templates',
                component: () =>
                    import ('../views/Pages/NotificationTemplates.vue'),
            },
            {
                path: '/user-sms',
                component: () =>
                    import ('../views/Pages/SMS.vue'),
            },
            {
                path: '/user-emails',
                component: () =>
                    import ('../views/Pages/Email.vue'),
            },
            {
                path: '/contacts',
                component: () =>
                    import ('../views/Pages/Contacts.vue'),
            },
            {
                path: '/contact-groups',
                component: () =>
                    import ('../views/Pages/ContactGroups.vue'),
            },
            {
                path: '/complete-profile',
                component: () =>
                    import ('../views/Pages/CompleteProfile.vue'),
            },
            {
                path: '/tender-application-1/:id',
                component: () =>
                    import ('../views/Pages/TenderApplicationSteps1.vue'),
                props: true,
            },
            {
                path: '/tender-application-2/:id',
                component: () =>
                    import ('../views/Pages/TenderApplicationSteps2.vue'),
                props: true,
            },
            {
                path: '/tender-application-3/:id',
                component: () =>
                    import ('../views/Pages/TenderApplicationSteps3.vue'),
                props: true,
            },
            {
                path: '/report',
                component: () =>
                    import ('../views/Pages/Reports.vue'),
            },
            {
                path: '/evaluation-report',
                component: () =>
                    import ('../views/Pages/EvaluationReport.vue'),
            },
            {
                path: '/users',
                component: () =>
                    import ('../views/Pages/Users.vue'),
            },
            {
                path: '/my-applications',
                component: () =>
                    import ('../views/Pages/MyApplications.vue'),
            },
        ]
    }

]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.VUE_BASE_URL,
    routes
})

// control auth in all routes
router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/register', '/home', '/reset-password', '/forgot-password'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('token');

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router