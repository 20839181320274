import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth.module'
import { alert } from './alert.module'
import { tender } from './tender.module'
import { user } from './user.module'
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex)
// Create store
export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    mini: false,
    loading: false,
    drawer: true
  },
  actions: {
    minifyDrawer({ commit }, val) {
      commit('minifyDrawer', val)
    },
    showDrawer({ commit }, val) {
      commit('showDrawer', val)
    },
    setLoading({ commit }, val) {
      commit('setLoading', val)
    }
  },
  mutations: {
    minifyDrawer(state, val) {
      state.mini = val
    },
    showDrawer(state, val){
      state.drawer = val
    },
    setLoading(state, val) {
      state.loading = val
    },
  },
  modules: {
    auth,
    alert,
    tender,
    user
  }
})
