<template>
  <div class="mx-auto my-1" max-width="100%">
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-card-title class="black--text">
     Tender No. {{ tender.tender_no }} :  {{ tender.title }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="">
      <div>
        {{ tender.description }}
      </div>
      <v-chip color="error" class="ml-0 mt-5" label outlined v-if="computedClosingDate">
        <v-avatar left>
          <v-icon small>mdi-clock-alert-outline</v-icon>
        </v-avatar>
        <countdown :time="computedClosingDate">
          <template slot-scope="props"
            >{{ props.days }} days, {{ props.hours }} hours,
            {{ props.minutes }} minutes, {{ props.seconds }} seconds.</template
          >
        </countdown>
      </v-chip>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: "TenderCard",
  components: {},
  data: () => ({
    selection: 1,
    timer: "",
  }),

  computed: {
    timeRemaining() {
      return this.timer;
    },
    computedClosingDate() {
      let result = this.getTimeRemaining(this.tender.closing_date).total;
      // console.log(result);
      // return result;
      if(result<0){
        return 0;
      }else{
        return result;
      }
    },
    tender() {
      return this.$store.state.tender.selected;
    },
  },

  methods: {
    initialize() {},

    createDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("-");
      return new Date(year, month, day);
    },
    getTimeRemaining(endtime) {
      endtime = this.createDate(endtime);
      const total =
        Date.parse(endtime) - Date.parse(new Date()) - 27 * 24 * 60 * 60 * 1000;
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));
      return {
        total,
        days,
        hours,
        minutes,
        seconds,
      };
    },
  },
};
</script>
