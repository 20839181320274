import AuthService from '../services/auth.service'
const userData = JSON.parse(localStorage.getItem('user'))

// const tender = user
//     ? { status: { loggedIn: true }, user }
//     : { status: { loggedIn: false }, user: null };

const initialState = {
    user: userData ? { status: { loggedIn: true }, userData }
        : { status: { loggedIn: false }, user: null },
        preq_uploaded_required_docs: {},
};
const snackbar = {
    show: false,
    message: '',
    color: ''
}

export const user = {
    namespaced: true,
    state: { ...initialState, snackbar },
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(
                response => {
                    console.log(response)
                    commit('loginSuccess', response.data)
                    return Promise.resolve(user)
                },
                error => {
                    commit('loginFailure', error)
                    return Promise.reject(error)
                }
            )
        },
        logout({ commit }) {
            AuthService.logout()
            commit('logout')
        },
        register({ commit }, user) {
            return AuthService.register(user).then(
                response => {
                    commit('registerSuccess', response)
                    return Promise.resolve(response.data)
                },
                error => {
                    console.log(error)
                    commit('registerFailure', error)
                    return Promise.reject(error)
                }
            )
        },
        setPreqUploadedRequiredDocs({ commit }, item) {
            commit('setPreqUploadedRequiredDocs', item)
        },
    },
    mutations: {
        // handler: () => { },
        loginSuccess(state, response) {
            console.log(response);
            state.status.loggedIn = true
            state.snackbar.show = true
            state.snackbar.color = 'success'
            state.snackbar.message = 'User login successful!'
            state.user = response.user
        },
        loginFailure(state, error) {
            console.log(error)
            state.status.loggedIn = false
            state.user = null
            state.snackbar.show = true
            state.snackbar.color = 'error'
            state.snackbar.message = error.response.data.message ||
                (error.response && error.response.data) ||
                error.mesage ||
                error.toString();
        },
        logout(state) {
            state.status.loggedIn = false
            state.user = null
            state.snackbar.show = false
            state.snackbar.color = ''
            state.snackbar.message = ''
        },
        registerSuccess(state) {
            state.status.loggedIn = true
            state.snackbar.show = true
            state.snackbar.color = 'success'
            state.snackbar.message = 'User registration successful!'
        },
        registerFailure(state, error) {
            console.log(error.response)
            state.status.loggedIn = false
            state.snackbar.show = true
            state.snackbar.color = 'error'
            state.snackbar.message = error.response.data.message
            state.snackbar.message = error.response.data.errors
        },
        setPreqUploadedRequiredDocs(state, item) {
            state.preq_uploaded_required_docs = item
        },
    },
    getters: {
        user: (state) => state.user
    }

}
