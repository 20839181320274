<template>
  <v-container class="lighten-5">
    <v-row no-gutters>
      <v-col cols="12" sm="3"> </v-col>
      <v-col cols="12" sm="6">
        <v-card
          elevation="2"
          class="mx-auto pt-8 mt-16"
          max-width="444"
          outlined
        >
          <div class="d-flex justify-center">
            <v-img
              lazy-src="img/logo-128.png"
              max-height="178"
              max-width="178"
              src="img/logo-128.png"
            ></v-img>
          </div>
          <v-divider class="mt-10 mb-5"></v-divider>
          <p class="text-center caption">Registration Form</p>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                dense
                v-model="user.name"
                append-icon="mdi-account-outline"
                label="Full Name *"
                :rules="nameRules"
                outlined
                placeholder=" "
              ></v-text-field>

              <v-text-field
                dense
                v-model="user.company_name"
                append-icon="mdi-home-city-outline"
                label="Business/Organisation/Company Name *"
                :rules="nameRules"
                outlined
                placeholder=" "
              ></v-text-field>

              <v-text-field
                dense
                v-model="user.email"
                append-icon="mdi-email-outline"
                :rules="emailRules"
                label="Company Email *"
                outlined
                placeholder=" "
              ></v-text-field>

              <v-text-field
                dense
                v-model="user.password"
                append-icon="mdi-lock-outline"
                :rules="passwordRules"
                :type="'password'"
                label="Password *"
                hint="At least 8 characters"
                outlined
                placeholder=" "
              ></v-text-field>

              <v-text-field
                dense
                v-model="confirmPassword"
                append-icon="mdi-lock-outline"
                :rules="passwordRules"
                :type="'password'"
                name="input-10-1"
                label="Confirm Password *"
                hint="At least 8 characters"
                outlined
                placeholder=" "
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-divider class="mt-n4 mb-4"></v-divider>
          <v-card-actions>
            <v-btn
              color="white"
              medium
              class="ml-3 grey--text"
              @click="gotToLogin"
              outlined
              >Back to Login</v-btn
            >
            <v-btn
              color="grey"
              elevation="2"
              medium
              width="110"
              class="ml-auto mr-2 white--text"
              @click="handleRegister"
              :loading="loading"
              >Register</v-btn
            >
          </v-card-actions>
          <v-divider class="mt-5"></v-divider>
          <div
            class="grey white--text d-flex justify-center pa-2"
            border="none"
          >
            Supplies management
          </div>
        </v-card>

        <div class="d-flex justify-center mt-5">
          <span class="text-caption">2020 © Software Technologies Ltd.</span>
        </div>
        <div class="d-flex justify-center">
          <v-img
            lazy-src="img/vendor.png"
            max-height="100"
            max-width="100"
            src="img/vendor.png"
          ></v-img>
        </div>
      </v-col>
      <v-col cols="12" sm="3"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import User from "../../models/user";

export default {
  data: () => ({
    user: new User("", "", "", ""),
    confirmPassword: "",
    valid: true,
    remember: 0,
    passwordRules: [
      (v) => !!v || "Password is invalid",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    nameRules: [
      (v) => !!v || "Name is required",
      // (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      // (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
  }),
  name: "Register",
  components: {},
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    loading() {
      return this.$store.state.loading;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    gotToLogin() {
      this.$router.push("/login");
    },
    handleRegister() {
      this.$store.dispatch("setLoading", true);
      this.$refs.form.validate();
      if (
        this.user.name &&
        this.user.company_name &&
        this.user.email &&
        this.user.password &&
        this.confirmPassword
      ) {
        this.$store.dispatch("auth/register", this.user).then(
          (response) => {
            if (response.status == 200) {
              this.$router.push("/login");
              this.$store.dispatch("setLoading", false);
            } else {
              console.log(response);
              this.$store.dispatch("setLoading", false);
            }
          },
          (error) => {
            this.$store.dispatch("setLoading", false);
            console.log(error);
          }
        );
      } else {
        this.$store.dispatch("setLoading", false);
      }
    },
  },
};
</script>
