import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#F3565D',
                alt: '#DFBA49',
                info: '#89C4F4',
                success: '#26C281',
                secondary: '#808080',
                // accent: '#8c9eff',
                error: '#F3565D',
                // dark: '#676E8A'
            },
        },
    },
});