import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueGoogleCharts from 'vue-google-charts';
import VueCountdown from '@chenfengyuan/vue-countdown';
import TenderService from "./services/tender.service";
import TenderCard from "@/components/TenderCard";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';


Vue.component('vue-phone-number-input', VuePhoneNumberInput);

Vue.prototype.$tender_service = TenderService;

Vue.config.productionTip = false

Vue.use(VueGoogleCharts);
Vue.use(TenderService);

// use this package's plugin
Vue.use(CKEditor);

Vue.component(TenderCard);
Vue.component(VueCountdown.name, VueCountdown);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')